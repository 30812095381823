import { useTranslations } from 'next-intl';

import { SecondaryLinkButton } from '@/components/buttons/SecondaryLinkButton';

import MarketDescriptionText from './MarketDescriptionText';
import MarketDescriptionTitle from './MarketDescriptionTitle';

type MarketDescriptionProps = {
  marketName: string;
  marketDescription: string | undefined;
  locationsCount: number;
};

export default function MarketDescription({
  marketName,
  marketDescription,
  locationsCount,
}: MarketDescriptionProps) {
  const t = useTranslations('markets.description');

  return (
    <div className="flex flex-col px-5 pt-8 pb-10 sm:px-10 md:pt-12 xl:container lg:pt-16">
      {marketDescription && (
        <>
          <MarketDescriptionTitle text={t('title', { marketName })} />
          <MarketDescriptionText text={marketDescription} />
        </>
      )}

      <MarketDescriptionTitle text={t('industrious-difference.title')} />
      <MarketDescriptionText text={t('industrious-difference.text')} />
      <div className="mb-5 rounded-[3px] bg-almond-97 p-5 flex flex-col gap-5">
        <p>
          {t('final-text', {
            count: locationsCount,
            marketName,
          })}
        </p>
        <SecondaryLinkButton href="/locations">
          {t('see-all-locations')}
        </SecondaryLinkButton>
      </div>
    </div>
  );
}
