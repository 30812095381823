import { useLocationImageOrPlaceholder } from '@/hooks/useLocationImagePlaceholder';
import { SimpleLocationSearchDto } from '@/lib/locations/dto';

import { ImageWithPlaceholder } from '../ImageWithPlaceholder';

type LocationCardImageProps = {
  priority: boolean;
  location: SimpleLocationSearchDto;
  height?: number;
};

export default function LocationCardImage({
  priority,
  location,
  height = 220,
}: LocationCardImageProps) {
  const { src, alt } = useLocationImageOrPlaceholder(location.image);

  return (
    <ImageWithPlaceholder
      className="object-cover object-center rounded-lg w-full md:max-w-[40%] md:w-[300px]"
      style={{
        height,
      }}
      sizes="(max-width: 640px) 100vw, 300px"
      width={300}
      height={height}
      src={src}
      alt={alt}
      priority={priority}
    />
  );
}
