'use client';

import { useState } from 'react';

import { RootLayout } from '@/components/RootLayout';
import { BreadcrumbListSchema } from '@/components/head/BreadcrumbListSchema';
import MarketDescription from '@/components/markets/MarketDescription';
import MarketDiscovery from '@/components/markets/MarketDiscovery';
import { getMetroBreadcrumbList } from '@/lib/breadcrumb-schemas';
import { MarketWithLocationsDto } from '@/lib/markets/dto/market.dto';
import { getServerConfig } from '@/utils/config';
import { SearchViewMode } from '@/utils/constants';

import SwitchViewButton from '../search/SwitchViewButton';

export type MarketsPageProps = {
  market: MarketWithLocationsDto;
  mapboxAccessToken: string;
};

export function MarketsPage({ market, mapboxAccessToken }: MarketsPageProps) {
  const { HOST } = getServerConfig();

  const [viewMode, setViewMode] = useState<SearchViewMode>(SearchViewMode.List);

  return (
    <RootLayout noContainer>
      <BreadcrumbListSchema
        schema={getMetroBreadcrumbList({
          host: HOST,
          name: market.name,
          slug: market.slug,
        })}
      />
      <main>
        <MarketDiscovery
          market={market}
          mapboxAccessToken={mapboxAccessToken}
          isFullScreenMap={viewMode === SearchViewMode.Map}
        />
        <MarketDescription
          marketName={market.name}
          marketDescription={market.description}
          locationsCount={market.locations.length}
        />
        <SwitchViewButton
          onClick={() => {
            window.scroll(0, 0);
            setViewMode(
              viewMode === SearchViewMode.List
                ? SearchViewMode.Map
                : SearchViewMode.List
            );
          }}
          viewMode={viewMode}
        />
      </main>
    </RootLayout>
  );
}
