import { useTranslations } from 'next-intl';

import { SecondaryLinkButton } from '../buttons';

type SearchNoResults = {
  hasFilters?: boolean;
  noResultsWithFiltersMessage?: string;
};

export default function SearchNoResults({
  hasFilters,
  noResultsWithFiltersMessage,
}: SearchNoResults) {
  const t = useTranslations('search');

  return (
    <div className="min-h-[calc(100vh-150px)] lg:min-h-0 text-sm lg:text-base">
      <p className="font-bold">
        {noResultsWithFiltersMessage !== null && hasFilters
          ? noResultsWithFiltersMessage
          : t(hasFilters ? 'no-results-with-filters' : 'no-results')}
      </p>
      <div className="p-4 mt-4 rounded-sm bg-almond-99">
        <p>{t('learn-more-network')}</p>
        <SecondaryLinkButton
          className="block py-[6px] text-base mt-2"
          href="/location-form"
        >
          {t('contact-us')}
        </SecondaryLinkButton>
      </div>
    </div>
  );
}
