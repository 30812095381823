import { forwardRef } from 'react';

import clsx from 'clsx';

type CardListWrapperProps = {
  withMap: boolean;
} & React.ComponentPropsWithoutRef<'div'>;

const CardListWrapper = forwardRef<HTMLDivElement, CardListWrapperProps>(
  ({ children, withMap, className, ...divProps }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(
          'lg:flex-1 p-5 sm:px-10 sm:pr-5 mt-4',
          withMap &&
            'lg:overflow-y-scroll xl:pl-[calc(((100vw-1440px)/2)+2rem)]',
          !withMap && 'max-w-[1133px] m-auto',
          className
        )}
        data-testid="search-list-wrapper"
        {...divProps}
      >
        {children}
      </div>
    );
  }
);

CardListWrapper.displayName = 'CardListWrapper';

export default CardListWrapper;
