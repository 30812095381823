import { Feature, Geometry } from 'geojson';
import mapboxgl from 'mapbox-gl';
import dynamic from 'next/dynamic';
import { MutableRefObject, useEffect, useRef, useState } from 'react';

import { OnMoveEndProps } from '@/hooks/useMap';
import { ScreenSize, useScreenSize } from '@/hooks/useScreenSize';
import { LatLng } from '@/models/LatLng';
import { usePathname } from '@/navigation';
import * as gtm from '@/utils/gtm';
import { LocationMapFeatureProps } from '@/utils/mapbox';

import { SearchType } from '../search/search-type.enum';
import { getSearchType } from '../search/utils';
import Map from './Map';
import { TilesCarousel, TilesCarouselItem } from './TilesCarousel';
import { CameraMapOptions, MapItem } from './types';

const LocationMapPopup = dynamic(() => import('./LocationMapPopup'));
const LocationTileItem = dynamic(() => import('./LocationTileItem'));

type MapProps<T extends MapItem> = {
  initialCameraOptions: CameraMapOptions;
  cameraOptions?: CameraMapOptions;
  features: Feature<Geometry, T>[];
  mapboxAccessToken: string;
  highlightedSlug?: string | undefined;
  isFullScreenMap?: boolean;
  staticMapRef: MutableRefObject<HTMLDivElement | null>;
  onIdle?: (
    arrayOfLocationsSlug: string[],
    newCoords: LatLng | undefined
  ) => void;
  onMoveEnd?: (props: OnMoveEndProps) => void;
};

export default function LocationMap<T extends MapItem>({
  features,
  ...mapProps
}: MapProps<T>) {
  const [locationSelected, setLocationSelected] = useState<
    LocationMapFeatureProps | undefined
  >();
  const pathname = usePathname();
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const widthWindow = useScreenSize();
  const isDataLayerLoaded = gtm.isDataLayerLoaded();
  const searchType = getSearchType(pathname);

  const handleMapRefChange = (ref: MutableRefObject<mapboxgl.Map | null>) => {
    if (!mapRef.current) {
      mapRef.current = ref.current;
    }
  };

  useEffect(() => {
    if (
      !isDataLayerLoaded ||
      !locationSelected ||
      searchType !== SearchType.Coworking
    )
      return;

    gtm.pushECommerceEvent('view_item_list', {
      item_list_id: 'search_page',
      item_list_name: 'Search page',
      items: [gtm.mapCoworkingToItemEcommerce(locationSelected)],
    });
  }, [isDataLayerLoaded, locationSelected, searchType]);

  return (
    <>
      <Map
        {...mapProps}
        features={features}
        onMapRefChange={handleMapRefChange}
        onMarkerClicked={(x) =>
          setLocationSelected(x as LocationMapFeatureProps | undefined)
        }
      />
      {mapRef && locationSelected && widthWindow > ScreenSize.LG && (
        <div className="hidden">
          <LocationMapPopup map={mapRef} location={locationSelected} />
        </div>
      )}
      {locationSelected && widthWindow <= ScreenSize.LG && (
        <TilesCarousel>
          <TilesCarouselItem>
            <LocationTileItem location={locationSelected} />
          </TilesCarouselItem>
        </TilesCarousel>
      )}
    </>
  );
}
