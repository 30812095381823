import { useTranslations } from 'next-intl';
import { useRef } from 'react';

import { useWebGL } from '@/hooks/useWebGL';
import { MarketWithLocationsDto } from '@/lib/markets/dto/market.dto';
import { DEFAULT_MAP_SEARCH_ZOOM } from '@/utils/constants';
import {
  getCenterFromFeatures,
  getMapboxFeaturesLocations,
} from '@/utils/mapbox';

import LocationSearchList from '../locations-search/LocationSearchList';
import SearchNoResults from '../locations-search/SearchNoResults';
import LocationMap from '../map/LocationMap';
import SearchStaticMap from '../map/SearchStaticMap';
import CardListWrapper from '../search/CardListWrapper';
import SearchMapWrapper from '../search/SearchMapWrapper';
import SearchTitle from '../search/SearchTitle';
import useHighlightedSlug from '../search/useHighlightedSlug';

import clsx from 'clsx';

type MarketDiscoveryProps = {
  market: MarketWithLocationsDto;
  mapboxAccessToken: string;
  isFullScreenMap?: boolean;
};

export default function MarketDiscovery({
  market,
  mapboxAccessToken,
  isFullScreenMap,
}: MarketDiscoveryProps) {
  const t = useTranslations('markets');
  const { isWebGLSupported } = useWebGL();
  const staticMapRef = useRef(null);

  const { highlightedSlug, onMouseEnter, onMouseLeave } = useHighlightedSlug();

  const { name: marketName, locations } = market;

  const featuresLocations = getMapboxFeaturesLocations(locations);
  const initialCameraOptions = {
    center: getCenterFromFeatures(featuresLocations),
    zoom: DEFAULT_MAP_SEARCH_ZOOM,
  };

  return (
    <div
      className={clsx(
        'lg:overflow-hidden shadow lg:h-[calc(100vh-70px)]',
        isFullScreenMap &&
          'overflow-hidden h-[calc(100vh-48px)] lg:h-[calc(100vh-70px)]'
      )}
    >
      <div className="flex flex-col h-full">
        <div className="flex flex-col-reverse w-full h-full lg:flex-row">
          <CardListWrapper
            className={clsx(isFullScreenMap && 'hidden')}
            withMap={isWebGLSupported !== false}
          >
            <SearchTitle className="my-4" text={t('title', { marketName })} />

            {locations.length > 0 && (
              <LocationSearchList
                locations={locations}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              />
            )}

            {locations.length === 0 && <SearchNoResults />}
          </CardListWrapper>

          <SearchMapWrapper
            withMap={isWebGLSupported !== false}
            isFullScreenMap={isFullScreenMap}
          >
            {isWebGLSupported !== true && (
              <SearchStaticMap
                ref={staticMapRef}
                coords={initialCameraOptions}
              />
            )}

            {isWebGLSupported === true && (
              <LocationMap
                initialCameraOptions={initialCameraOptions}
                features={featuresLocations}
                mapboxAccessToken={mapboxAccessToken}
                isFullScreenMap={isFullScreenMap}
                highlightedSlug={highlightedSlug}
                staticMapRef={staticMapRef}
              />
            )}
          </SearchMapWrapper>
        </div>
      </div>
    </div>
  );
}
